<template>
  <div class="info-content"
    ref="pie-box"
    style="height: 100%;">

    <ModelBg title="设备状态油耗占比"
      style="padding-bottom: 2px">
      <el-empty :image-size="25"
        style="height: 100%;"
        v-if="JSON.stringify(options)=='{}'"></el-empty>
      <div style="height:100%;"
        v-else>
        <Chart style="height:100%;min-height: 100px"
          ref="chartBox"
          :option="pieOptions"></Chart>
      </div>
    </ModelBg>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ModelBg from './components/ModelBg';
import Chart from "@/components/Chart/Charts";
export default {
  components: {
    ModelBg,
    Chart
  },
  props: {
    options: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      pieOptions: {}
    };
  },
  watch: {
    options(val) {
      if (val) {
        this.$nextTick(() => {
          this.getBarOptions(this.options);
        });
      }
    }
  },
  methods: {
    getBarOptions(obj) {
      let DataLists = [{}, {}, {}, {}];
      DataLists[0].value = obj.runningOil;
      DataLists[0].name = "行走油耗";
      DataLists[1].value = obj.idlingOil;
      DataLists[1].name = "怠速油耗";
      DataLists[2].value = obj.workingOil;
      DataLists[2].name = "作业油耗";
      DataLists[3].value = obj.abnormalOil;
      DataLists[3].name = "异常油耗";

      const getSize = (res,type) => {
        const clientWidth = this.$refs['pie-box'].offsetWidth;
        const clientHeight = this.$refs['pie-box'].offsetHeight;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        const fontSize2 = 100 * (clientHeight / 1080);
        return res * (type == 2 ? fontSize2 : fontSize);
      };
      const sizeHNum = getSize(0.3, 2)
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}升 ({d}%)",
          confine: true,
        },
        color: ['#FFBB33', '#FFF27D', '#B7E77A', '#F87979'],
        legend: {
          itemWidth: sizeHNum * 2,
          itemHeight: sizeHNum * 2,
          icon: "circle",
          itemGap: 1,
          bottom: 2,
          textStyle: {
            fontSize: sizeHNum * 2,
            fontWeight: 400,
            color: '#fff',
            rich: {
              a: {
                verticalAlign: 'middle',
              }
            },
            lineHeight: 12,
            padding: [0, -2, -4, -4],
          },
          itemGap: 14,
          data: ["行走油耗", "怠速油耗", "作业油耗", "异常油耗"],
          // selectedMode: false

        },
        series: [
          {
            type: "pie",
            radius: ['48%', '70%'],
            center: ["50%", "54%"],
            // height: '78%',
            bottom: '40px',
            selectedMode: "single",
            data: DataLists,
            avoidLabelOverlap: true,
            selectedMode: false, //禁止点击饼图区域
            emphasis: {
              focus: 'none',
              scaleSize: 0,
              disable: false,
              scale: false,
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {        //饼图按块划分时是否需要用线隔开，  不需要注释即可
              normal: {
                borderWidth: 3,
                borderColor: '#2C2D3B'
              }
            },
            labelLine: {
              normal: {
                length: 10,
                length2: 43,
                lineStyle: {
                  type: "dashed",
                  color: "#cccccc"
                },
                position: "outside"
              },
              maxSurfaceAngle: 80,
              showAbove: true
            },
            label: {
              color: '#fff',
              formatter: [
                "{sizeT|{b}}",
                "{he|}",
                "{he|}",
                "{valueHead|{d}%}",
              ].join("\n"),
              rich: {
                title: {
                  align: "center",
                },
                hr: {
                  borderColor: "#fff",
                  width: "100%",
                  borderWidth: 0.5,
                  borderType: 'dotted',
                  height: 0,
                },
                he: {
                  height: 3,
                },
                valueHead: {
                  color: "#F7981D",
                  align: "center",
                  fontSize: sizeHNum * 2.5,
                  fontWeight: 700,
                },
                sizeT: {
                  fontSize: sizeHNum * 2,
                }
              },
            },
          },
        ],
      };
      this.pieOptions = option
      // let barBox = echarts.init(this.$refs.chartBox, null, {});
      // barBox.setOption(option);
    },
  }
}
</script>
<style lang="scss" scoped>
@import './DataCenterStyle.scss';
</style>