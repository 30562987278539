<template>
  <div class="info-content" style="height: 100%">
    <!-- <div class="card-title">
      <span class="line"></span>工时统计
      <label></label>
    </div>
    <div class="button-group">
      <el-button
        :class="[timeType === 0 && 'activeBtn']"
        size="mini"
        @click="changeType(0)"
      >周</el-button>
      <el-button
        :class="[timeType === 1 && 'activeBtn']"
        size="mini"
        @click="changeType(1)"
      >月</el-button>
      <el-button
        :class="[timeType === 2 && 'activeBtn']"
        size="mini"
        @click="changeType(2)"
      >年</el-button>
    </div> -->

    <ModelBg title="工时分析" width="100%" v-if="showStateLine">
      <div style="height: 100%;" ref="line-box">
        <Chart
          style="height: 100%;"
          :option="lineOptions"
        ></Chart>
      </div>
    </ModelBg>
    <ModelBg title="工时分析" width="100%" v-else>
      <div style="height: 100%; overflow: auto" ref="line-box">
        <Chart
          style="height: 100%;"
          :option="statelineOptions"
        ></Chart>
      </div>
    </ModelBg>

    <div class="topSelect">
      <el-select
        v-model="classValue"
        placeholder="请选择"
        size="mini"
        style="width: 70px;border-radius: 4px;"
        @change="changeLine"
        popper-class="dropdown-item"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in classType"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="timeType"
        @change="changeType"
        popper-class="dropdown-item"
        style="width: 45px;border-radius: 4px;"
        :popper-append-to-body="false"
        size="mini"
      >
        <el-option
          v-for="item in timeTypeArry"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <!-- <div class="button-group">
      <div class="tabs" :class="[timeType === 1 && 'activeBtn']" size="mini" @click="changeType(1)">周</div>
      <div class="tabs" :class="[timeType === 2 && 'activeBtn']" size="mini" @click="changeType(2)">月</div>
      <div class="tabs" :class="[timeType === 3 && 'activeBtn']" size="mini" @click="changeType(3)">年</div>
    </div> -->
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import ModelBg from "./components/ModelBg";
import * as echarts from "echarts";
import { getVehicleStart } from "@/api/getData";
import { getMapCarWorkTime } from "@/api/Map/mapRequst.js";
import { typeOption } from "./config";
export default {
  components: {
    Chart,
    ModelBg,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  async created() {
   //  let res = await getVehicleStart();
   //  this.carStateData = res.obj;
   //  console.log(res);
  },
  watch: {
    options(val) {
      if (val) {
        this.$nextTick(() => {
          this.lineOptions = this.getLineOptions(val);
        });
      }
    },
    showTimeLine() {}
  },
  data() {
    return {
      lineOptions: {},
      timeTypeArry: [
        { value: 3, label: "年" },
        { value: 2, label: "月" },
        { value: 1, label: "周" },
      ],
      timeType: 1, //1：周，2：月，3：年
      classType: [
        { value: 1, label: "按状态" },
        { value: 2, label: "按种类" },
      ],
      classValue: 1,
      carStateData: {},
      showStateLine: true,
      statelineOptions: {},
    };
  },
  mounted() {},
  methods: {
   async getTypeOption(){
      let { seriesArr, dayDury } = await getMapCarWorkTime(this.timeType);
      // typeOption.grid.left = w;
      // typeOption.series = seriesArr;
      // typeOption.xAxis.data = dayDury;
      const getSize = (res,type) => {
        const clientWidth = this.$refs['line-box'].offsetWidth;
        const clientHeight = this.$refs['line-box'].offsetHeight;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        const fontSize2 = 100 * (clientHeight / 1080);
        return res * (type == 2 ? fontSize2 : fontSize);
      };
      const sizeWNum = getSize(0.3, 1)
      const sizeHNum = getSize(0.3, 2)
      console.log(seriesArr, dayDury);
      const typeOption = {
        grid: {
          left: sizeWNum * 4,//要修改
          right: sizeWNum * 5,
          bottom: sizeHNum * 3,
          top: sizeHNum * 8,
          containLabel: true
        },
        legend: {
          bottom: -1,
          left: "center",
          itemWidth: sizeHNum * 2, //设置宽度
          itemHeight: sizeHNum * 2, //设置高度
          itemGap: sizeHNum * 3,
          icon: "roundRect",
          textStyle: {
            color: '#FFB400',
            fontSize: sizeHNum * 2.5,
            fontWeight: 700
          },
          padding: 0
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data:dayDury,//要修改
          axisLabel: {
            show: true,
            textStyle: {
              color: '#8490B1', //更改坐标轴文字颜色
            }
          },
          nameTextStyle: {
            fontSize: 8      //更改坐标轴文字大小
          },
          splitLine: {
            show: false,

          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: '工时/小时',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#8490B1',  //更改坐标轴文字颜色
              fontSize: sizeHNum * 2
            }
          },
          nameTextStyle: {
            fontSize: sizeHNum * 2      //更改坐标轴文字大小
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffffff'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#8490B1',
              width: 1,
            }
          },
          axisTick: {
            show: false
          }

        },
        series:seriesArr,//////////要修改
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {}
          },
          confine: true,
          padding: sizeHNum * 1.5,
          formatter: function (params) {//可能会有问题
            let str = '';
            str += params[0].axisValue + '<br>';
            params.map(val => {
              str += val.marker + val.seriesName + '：' + `${parseInt(val.value)}时${parseInt((val.value * 3600 / 60) % 60)}分 ` + '<br>';
            });
            return str;
          },
        },
      }
      this.statelineOptions = typeOption;
    },
    async changeLine(e) {
      if (e === 1) {
        this.showStateLine = true;
        this.$emit("modal-change", this.timeType);
        return;
      }
      await this.getTypeOption();
      this.showStateLine = false;
    },
    async changeType(type) {
      this.timeType = type;
      if (!this.showStateLine) {
        await this.getTypeOption();
        return;
      }
      this.$emit("modal-change", type);
    },
    getCarClassData() {
      return;
    },
    getLineOptions(options) {
      const boxW = this.$refs["line-box"].offsetWidth;
      const w = boxW / 28;
      const w2 = boxW / 45;
      const getSize = (res,type) => {
        const clientWidth = this.$refs['line-box'].offsetWidth;
        const clientHeight = this.$refs['line-box'].offsetHeight;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        const fontSize2 = 100 * (clientHeight / 1080);
        return res * (type == 2 ? fontSize2 : fontSize);
      };
      const sizeWNum = getSize(0.3, 1)
      const sizeHNum = getSize(0.3, 2)
      return {
        grid: {
          left: sizeWNum * 4,//要修改
          right: sizeWNum * 5,
          bottom: sizeHNum * 3,
          top: sizeHNum * 8,
          containLabel: true,
        },
        legend: {
          bottom: -1,
          left: "center",
          itemWidth: sizeHNum * 2, //设置宽度
          itemHeight: sizeHNum * 2, //设置高度
          itemGap: sizeHNum * 3,
          icon: "roundRect",
          textStyle: {
            color: "#FFB400",
            fontSize: sizeHNum * 2.5,
            fontWeight: 700,
          },
          padding: 0,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: options.map((p) => p.dateDay),
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8490B1", //更改坐标轴文字颜色
            },
          },
          nameTextStyle: {
            fontSize: 8, //更改坐标轴文字大小
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          name: "工时/小时",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8490B1", //更改坐标轴文字颜色
              fontSize: sizeHNum * 2
            },
          },
          nameTextStyle: {
            fontSize: sizeHNum * 2, //更改坐标轴文字大小
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#ffffff",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#8490B1",
              width: 1,
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "行走",
            data: options.map((p) => p.walkingTime / 3600),
            type: "line",
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: "#FFBB33", //折点颜色
                lineStyle: {
                  color: "##FFBB33", //折线颜色
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 187, 51, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255, 0.3)",
                },
              ]),
            },
          },
          {
            name: "通电",
            data: options.map((p) => p.electricityTime / 3600),
            type: "line",
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: "#61BBFF", //折点颜色
                lineStyle: {
                  color: "#61BBFF", //折线颜色
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(97, 187, 255, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255, 0.3)",
                },
              ]),
            },
          },
          {
            name: "怠速",
            data: options.map((p) => p.idleSpeed / 3600),
            type: "line",
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: "#FFF27D", //折点颜色
                lineStyle: {
                  color: "#FFF27D", //折线颜色
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 242, 125, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255, 0.3)",
                },
              ]),
            },
          },
          {
            name: "作业",
            data: options.map((p) => p.workTime / 3600),
            type: "line",
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: "#B7E77A", //折点颜色
                lineStyle: {
                  color: "#B7E77A", //折线颜色
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(183, 231, 122, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255, 0.3)",
                },
              ]),
            },
          },
          {
            name: "熄火",
            data: options.map((p) => p.shutdownTime / 3600),
            type: "line",
            smooth: 0.6,
            itemStyle: {
              normal: {
                color: "#D9D9D9", //折点颜色
                lineStyle: {
                  color: "#D9D9D9", //折线颜色
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 242, 125, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(217, 217, 217, 1)",
                },
              ]),
            },
          },

          //  {
          //    data: options.map(p => p.manHour),
          //    type: 'line',
          //    smooth: true,
          //    itemStyle: {
          //      normal: {
          //        color: '#9AFFE4', //折点颜色
          //        lineStyle: {
          //          color: '#9AD8EB' //折线颜色
          //        }
          //      }
          //    },
          //    areaStyle: {
          //      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          //        offset: 0,
          //        color: 'RGBA(74, 134, 174, 1)'
          //      }, {
          //        offset: 1,
          //        color: 'RGBA(74, 134, 174, 0.1)'
          //      }])
          //    },
          //  }
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            label: {},
          },
          confine: true,
          padding: sizeHNum * 1.5,
          formatter: function (params) {
            let str = "";
            str += params[0].axisValue + "<br>";
            params.map((val) => {
              str +=
                val.marker +
                val.seriesName +
                "：" +
                `${parseInt(val.value)}时${parseInt(
                  ((val.value * 3600) / 60) % 60
                )}分 ` +
                "<br>";
            });
            return str;
          },
          textStyle: {
            fontSize: sizeHNum * 2.8
          }
        },
      };
    },
  },
};
</script>

<!-- <style lang="scss">
.el-select {
    .el-input__suffix {
      right: 0 !important;
    }
    .el-input__inner {
      padding: 8px !important;
    }
}
</style> -->
<style lang="scss" scoped>
@import "./DataCenterStyle.scss";

::v-deep .el-select {
    .el-input__suffix {
      right: 0 !important;
      background-color: rgba(0, 138, 255, 1);
    }
    .el-input__inner {
      padding: 8px !important;
    }
    .el-input__icon {
      width: 14px;
      color: #fff;
    }
    .el-scrollbar {
      background-color: rgba(0, 138, 255, .5);
    }
    .popper__arrow {
      top: -7px;
      border-bottom-color: rgb(0, 138, 255);
      left: 50% !important;
      transform: translateX(-50%);
    }
    .popper__arrow::after {
      border-bottom-color: rgb(0, 138, 255);
    }
}

.activeBtn {
  // background: #3fabfe !important;
  // color: #fff !important;
  color: #f7981d !important;
}
::v-deep .topSelect {
  display: flex;
  .el-input__inner {
    background-color: transparent !important;
    color: #f7981d !important;
  }
  .el-select:nth-child(1) {
    position: absolute;
    top: 5%;
    right: 36px;
    width: 100px;
    border: 1px solid #008aff;
    z-index: 99;
    border-radius: 4px 0px 0px 4px;
    .el-input {
      input {
        border-color: rgba(44, 137, 229, 0.5);
        color: #f7981d !important;
      }
    }
  }

  .el-select:nth-child(2) {
    position: absolute;
    top: 5%;
    right: 115px;
    width: 80px;
    border: 1px solid #008aff;
    z-index: 99;
    border-radius: 4px 0px 0px 4px;
    .el-input {
      input {
        border-color: rgba(44, 137, 229, 0.5);
        color: #f7981d !important;
      }
    }
  }

  .dropdown-item {
    background-color: transparent !important;
    color: #f7981d !important;
    border: 1px solid rgb(0, 138, 255);
    .el-select-dropdown__item {
      background-color: transparent !important;
      color: #f7981d !important;
      padding: 0 10px;
      text-align: center;
    }
    .el-select-dropdown__list {
      padding: 0;
      .el-select-dropdown__item+.el-select-dropdown__item {
        border-top: 1px solid rgb(0, 138, 255);
      }
    }
    .el-select-dropdown {
      border: #f7981d;
    }
    ::v-deep .el-input__suffix {
      right: 0;
    }
  }
}
</style>