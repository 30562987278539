<template>
  <div class="info-content" style="height: 100%;">
    <!-- <div class="card-title">
      <span class="line"></span>区域报警TOP5
      <label></label>
    </div> -->
    <ModelBg title="预警种类统计" style="padding-bottom: 15px">
      <div style="height: 100%" ref="conter-box">
        <el-empty v-if="!options.length" style="height: 100%" :image-size="25"></el-empty>
        <div v-else :style="conterBoxs"> 
          <!-- <Chart style="height:100%;min-height: 100px" :option="rankOption"></Chart> -->
          <vue-seamless-scroll :data="options" style="width: 100%;height: 100%;" :class-option="defaultOption">
            <div class="area" v-for="(item, index) in options" :key="index">
              <div class="progress-label">
                <span>{{ item.label }}</span>
                <span><i>{{ item.value || 0 }}</i> 条</span>
              </div>
              <div>
                <Progress :percentage="item.progressValue" color="#9DFEFF" :showText="false" :conterHeight="4" :isRadius="false" stroke-linecap="square" :proTextStyle="proTextStyle" :outerStyle="outerStyle" isImage imgUrl="https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/data-p1.png" :perStyle="{borderRadius: 0}" :stroke-width="20" />
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </ModelBg>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import Chart from "@/components/Chart/Charts";
import ModelBg from './components/ModelBg';
import Progress from '@/components/Progress'
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  components: {
    Chart,
    ModelBg,
    Progress,
    vueSeamlessScroll
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      rankOption: {},
      conterBoxs: "",
      proTextStyle: {},
      outerStyle: {},
      conterHeight: 0,
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    };
  },

  watch: {
    options(val) {
      if (val) {
        this.$nextTick(() => {
          this.rankOption = this.getRankOption(val);
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.rankOption = this.getRankOption();
    });
  },
  methods: {
    getRankOption() {
      if (this.$refs['conter-box']) {
        let w = this.$refs['conter-box'].offsetWidth;
        let h = this.$refs['conter-box'].offsetHeight;
        this.conterBoxs = `padding:${ h * 0.05 }px ${ w * 0.066 }px;height: 100%;display: flex;flex-wrap: wrap;align-items: start;`;
        this.proTextStyle = { color: '#F7981D', fontSize: '0.26rem' };
        // this.outerStyle = {border: '1px solid #80EBFF',opacity: 0.5,borderRadius: h * 0.2 * 0.15 + 'px',height: (h * 0.2 - (parseInt(h * 0.2 / 10)* 2)) *0.5 + 'px'};
        this.outerStyle = {border: '1px solid rgba(128, 235, 255, .5)',borderRadius: 4 + 'px',height: 16 + 'px'};
        this.conterHeight = 8;
      }
      let data = [];
      var temp = 0;
      for (var i = 0; i < this.options.length; i++) {
        var x = i;
        temp++;
        var params = {
          name: this.options[i].label,
          value: this.options[i].value,
          key: temp,
          itemStyle: {
            normal: {
              show: true
            }
          }
        };
        data.push(params);
      }
      return {
        tooltip: {
          trigger: 'item',
          formatter: function (a, b) {
            return a.name + '：' + a.value;
          },
        },
        grid: {
          borderWidth: 0,
          top: "10px",
          left: "21%",
          right: "2%",
          bottom: "2%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false,
              inside: false
            },
            data: this.options.map(p => p.label)
          },
          {
            type: "category",
            inverse: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            splitArea: {
              show: false
            },
            splitLine: {
              show: false
            },
            data: this.options.map(p => p.value)
          }
        ],
        series: [
          {
            name: "",
            type: "bar",
            zlevel: 6,
            showBackground: true,
            barWidth: "10px",
            data: data,
            // animationDuration: 1500,
            // animationEasing: "elasticOut",
            // barGap: "-100%",
            itemStyle: {
              normal: {
                position: "left",
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0.5, color: "#04B0E1" }, //柱图渐变色
                  { offset: 1, color: "#0160FC" } //柱图渐变色
                ]),
                //柱形图圆角，初始化效果
                barBorderRadius: [10, 10, 10, 10]
              }
            },

            label: {
              width: 12,
              interval: 0,
              normal: {
                show: true,
                position: "left",
                textStyle: {
                  color: "#ffffff",
                  fontSize: 14,
                  width: 12
                },
                formatter(val) {
                  if (val.name.length > 6) {
                    return `${val.name.slice(0, 6)}...`;
                  }
                  return `${val.name}`;
                }
              },
            }
          },
          {
            type: "bar",
            itemStyle: {
              normal: { color: "rgba(0,0,0,0.05)" }
            },
            barCategoryGap: "40%",
            data: [100, 100, 100, 100, 100],
            animation: false
          }
        ]
      };
    }
  }
}
</script>


<style lang="scss" scoped>
@import './DataCenterStyle.scss';
.area {
  // display: flex;
  // // padding: 12px 30px;
  // align-items: center;
  // padding: 2% 1%;
  margin-bottom: 10px;
  width: 100%;
  >span {
    color: #fff;
    font-size: 0.20rem;
    margin-right: 0.08rem;
  }
  >div {
    flex: 1;
  }
  >.progress-label {
    display: flex;
    justify-content: space-between;
    margin-right: 6px;
    font-size: 14px;
    color: #fff;
    i {
      font-size: 16px;
      color: rgba(247, 152, 29, 1);
    }
  }
}
</style>