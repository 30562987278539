<template>
  <div class="info-content"
    ref="pie-box"
    style="height: 100%;">
    <ModelBg title="TOP5设备油耗"
      style="padding-bottom: 2px">
      <div style="height:100%;overflow: auto;"
        v-if="options.length">
        <Chart style="height:calc(100% - 2px);min-height: 100px"
          :option="optionData"></Chart>
      </div>
      <el-empty :image-size="25"
        style="height: 100%;"
        v-else></el-empty>
    </ModelBg>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ModelBg from './components/ModelBg';
import Chart from "@/components/Chart/Charts";
export default {
  components: {
    ModelBg,
    Chart
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    title: {
      type: Object,
      default: () => {
        return {
          text: '',
          subtext: ''
        };
      }
    },
  },
  data() {
    return {
      optionData: {}
    };
  },
  watch: {
    options(val) {
      if (val) {
        this.$nextTick(() => {
          this.getBarOptions(this.options);
        });
      }
    }
  },
  mounted() {
  },
  methods: {
    getBarOptions(data) {
      let xData = data.map(item => item.plate),
        yData = [],
        barData = [],
        maxData;
      barData = data.map(item => item.oil);
      const maxScale = Math.max(...barData);
      maxData = new Array(5).fill(maxScale);

      const getSize = (res,type) => {
        const clientWidth = this.$refs['pie-box'].offsetWidth;
        const clientHeight = this.$refs['pie-box'].offsetHeight;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        const fontSize2 = 100 * (clientHeight / 1080);
        return res * (type == 2 ? fontSize2 : fontSize);
      };
      const sizeHNum = getSize(0.3, 2)
      let option = {
        grid: {
          top: sizeHNum * 5,
          left: sizeHNum * 2.5,
          bottom: sizeHNum * 0.5,
          right: sizeHNum * 2.5,
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
        },
        animation: false,
        xAxis: [
          {
            type: 'category',
            data: xData,
            show: true,
            axisTick: {
              //   show: true,
              inside: true, //刻度内置
              textStyle: {
                fontSize: 0,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(204, 204, 204, 1)",
              },
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              showMaxLabel: true,  //显示最大刻度
              textStyle: {
                opacity: 0,
                color: '#ccc',
                fontSize: sizeHNum * 2
              },
              formatter: function (value) {
                    if (value.length > 4) {
                      return `${value.slice(0, 4)}...`;
                    }
                    return value;
                  },
              margin: 10,
              interval: 0
            },

          },
        ],
        yAxis: [
          {
            name: "单位：升",
            nameGap: sizeHNum * 3,
            show: true,
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#cccccc",
              },
            },
            nameTextStyle: {
              fontSize: sizeHNum * 2
            },
            splitLine: {
              show: false
            },
          },
        ],
        series: [
          // {
          //   name: '上部圆',
          //   data: maxData,
          //   silent: true,
          //   // data: [741, 741, 741, 741, 741],
          //   type: "pictorialBar", // 背景顶部
          //   //   "barMaxWidth": "20",
          //   symbolPosition: "end",
          //   symbolOffset: [0, "-50%"],
          //   symbolSize: [20, 10],
          //   zlevel: -1,
          //   color: "#11427A",
          //   //    // color:'rgba(0, 138, 255, 0.2)',
          // },
          // {
          //   name: '底部圆',
          //   data: yData,
          //   silent: true,
          //   type: 'pictorialBar',
          //   silent: true,
          //   symbolSize: [20, 10],
          //   symbolOffset: [0, 7],
          //   z: 12,
          // },
          // {
          //   name: '背景圆柱',
          //   silent: true,
          //   data: maxData,
          //   type: "bar",
          //   barMaxWidth: "auto",
          //   barWidth: 20,
          //   barGap: "-100%",
          //   zlevel: 0, // 背景
          //   color: "#11427A",
          //   tooltip: {
          //     trigger: 'none',
          //   },
          //   // color: 'rgba(210,210,210,0.1)'
          //   // itemStyle: {
          //   //   normal: {
          //   //     color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
          //   //       {
          //   //         offset: 0,
          //   //         color: 'rgba(210,210,210,0.1)',
          //   //       },
          //   //       {
          //   //         offset: 1,
          //   //         color: 'rgba(210,210,210,0.1)',
          //   //       },
          //   //     ]),
          //   //     opacity: 0.8,
          //   //   },
          //   // },
          // },
          {
            name: '油耗',
            type: 'bar',
            barWidth: '20',
            z: 12,
            barGap: '-100%',
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: sizeHNum * 2,
                fontWeight: 'bold',
                color: '#fff',
              },
            },
            itemStyle: {
              opacity: 0.7,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#33ddff', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#785bff', // 100% 处的颜色
                    },
                  ],
                  false
                );
              },
              borderRadius: [27, 27, 0, 0],
            },
            tooltip: {
              trigger: "item",
              formatter: function (params) {
                let str = '';
                str += params.name + '<br>' + params.marker + params.seriesName + '：' + `${ params.data }升 ` + '<br>';
                return str;
              },
              // 弹窗位置计算
              position: function (point, params, dom, rect, size) {
                const { viewSize, contentSize } = size;
                const x = point[1]
                const y = point[0]
                const obj = {}
                if (( x - contentSize[1] ) > 0) {
                  obj.top = x - contentSize[1]
                } else if ((viewSize[1] - x) > contentSize[1]) {
                  obj.top = x
                }else {
                  obj.top = 0
                }
                if ((y - contentSize[0]) > 0) {
                  obj.left = y - contentSize[0]
                } else if ((viewSize[0] - y) > contentSize[0]) {
                  obj.left = y
                }else {
                  obj.left = 0
                }
                // 固定在顶部
                return obj;
              }
            },
            data: barData,
          },
          // {
          //   name: '上部圆',
          //   type: 'pictorialBar',
          //   silent: true,  //鼠标不高亮
          //   symbolSize: [20, 10],
          //   symbolOffset: [0, -6],
          //   symbolPosition: 'end',
          //   z: 12,
          //   color: '#33ddff',
          //   data: barData,
          // },
          // {
          //   name: '底部圆',
          //   type: 'pictorialBar',
          //   silent: true,
          //   symbolSize: [20, 10],
          //   symbolOffset: [0, 7],
          //   z: 12,
          //   color: '#144C83',
          //   data: barData,
          // },
        ],
      };
      // let barBox = echarts.init(this.$refs.chartBox, null, {});
      // barBox.setOption(option);
      this.optionData = option;
    },
    random(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './DataCenterStyle.scss';
</style>