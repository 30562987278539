import { render, staticRenderFns } from "./ModelBg.vue?vue&type=template&id=1c3f208c&scoped=true"
import script from "./ModelBg.vue?vue&type=script&lang=js"
export * from "./ModelBg.vue?vue&type=script&lang=js"
import style0 from "./ModelBg.vue?vue&type=style&index=0&id=1c3f208c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c3f208c",
  null
  
)

export default component.exports