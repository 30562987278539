<template>
  <div class="scrollText" ref="outer">
    <div class="st-inner" :class="{'st-scrolling': needToScroll}">
      <span class="st-section" ref="inner">{{data}}</span>
      <span class="st-section" v-if="needToScroll">{{data}}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      needToScroll: false,//用于控制第二个span是否需要
      text: ""
    };
  },
  props: {
    data: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.startCheck();
  },
  methods: {
    check() {
      // this.setText();
      this.$nextTick(() => {
        let flag = this.isOverflow();
        this.needToScroll = flag;
      });
    },

    //判断子元素宽度是否大于父元素宽度。超出则需要滚动，否则不滚动
    isOverflow() {
      let outerWidth = this.getWidth(this.$refs.outer);
      let innerWidth = this.getWidth(this.$refs.inner);
      return innerWidth > outerWidth;
    },

    //获取元素宽度
    getWidth(el) {
      let { width } = el.getBoundingClientRect();
      return width;
    },

    //增加定时器，每隔一秒check 一次
    startCheck() {
      this._checkTimer = setInterval(this.check, 1000);
      this.check();
      clearInterval(this._checkTimer);
    },
  },

}
</script>
<style lang="scss" scoped>
.scrollText {
  overflow: hidden;
  white-space: nowrap;
}
.st-inner {
  display: inline-block;
}
.st-scrolling .st-section {
  padding: 0 5 px;
}

//向左匀速滚动动画
.st-scrolling {
  animation: scroll 10s linear infinite;
}
.st-scrolling:hover {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
</style>